@import "media";

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: var(--scrollbar-width);
  color: var(--text_color);
  background: var(--bg_color);
}

html,
body {
  max-width: 100vw;
  font-family: var(--font_family);
  -webkit-font-smoothing: subpixel-antialiased;

  &.noscroll {
    position: fixed;
    overflow-y: hidden;
    width: 100%;
    padding-right: var(--scrollbar-width);
  }
}
