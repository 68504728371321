.root {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px;
  border-radius: 16px;

  &Info {
    background: rgba(0, 122, 255, 0.13);
    color: #007AFF;
  }

  &Warning {
    background: #FF9F0A1A;
    color: #FF9F0A;
  }
}

.icon {
  flex: 0 0 32px;
  width: 32px;
}

.texts {
  flex: 1 0 0;
}

.title {
  margin-bottom: 2px;
  display: block;
}

.text {
  display: block;
}

.arrow {
  flex: 0 0 18px;
}

.clickable {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}