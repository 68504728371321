@import "../../../styles/mixins";

.root {
  padding-top: 16px;

  .header {
    width: 100%;
    display: flex;
    padding: 0 16px;
    flex-direction: row;
    justify-content: space-between;

    .label {
      white-space: nowrap;
    }
  }
}

.shine {
  @include shine(true);

  box-sizing: border-box;
  width: 100%;
  height: 22px;
  margin-bottom: 12px;
  border-radius: 24px;

  * {
    color: transparent !important;
  }
}

.list {
  padding: 0 !important;
}
