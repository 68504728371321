.button {
  border-radius: 16px;
  position: relative;
  text-align: initial;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text {
  padding-right: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0.9;
}

.arrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}