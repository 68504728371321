@import "../../styles/mixins";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0;
  left: 0;
  width: 100%;

  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  padding: 8px 12px 4px;
  transform: translateZ(0);

  top: calc(var(--header_height) - var(--initial_header_height));
  z-index: var(--header_z_index);
  background: var(--bg_color);

  @include firstLayerTransition(top);

  &.android {
    padding-top: 12px;
  }
}

#searchResultsPortal {
  position: relative;
}

.left {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.logoWrapper {
  height: 40px;
  position: relative;
}

.right {
  display: flex;
  width: 100%;
  max-width: 495px;
  justify-content: flex-end;
  position: relative;
}

.button {
  flex: 0 0 auto;
}

.placeholder {
  height: var(--header_height);
  flex: 0 0 var(--header_height);

  @include firstLayerTransition(height);
  @include firstLayerTransition(flex);
}

.hidden {
  transform: translateY(-100%);
}
