@import "../../styles/mixins";

.root {
}

.empty {
}

.scroller {
  height: 100px;
  width: 200px;
}

.spinnerContainer {
  margin: 0 auto;
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 34px;
  height: 34px;
}
