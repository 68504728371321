@import "../../../styles/mixins";

.root {
  height: var(--initial_recently_launched_height);
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--bg_color);
  z-index: 1;

  @include firstLayerTransition(top);
}


.separatorWrapper {
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
}

.recentSeparator {
  margin: 12px 0 0;
  height: 0.5px;
  display: block;
  background-color: var(se);
  width: 100%;
  box-sizing: border-box;
}

.recentlyLaunchedHeader {
  padding: 0 16px;
}

.image {
  border-radius: 16px;
  width: 64px;
  height: 64px;
}

.appContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 8px 8px 0;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 8px;
  scroll-padding-right: 8px;

  @include noScrollbar();
}

.iconContainer {
  padding: 4px 8px;
}

.recentlyLaunchedItem {
  scroll-snap-align: start;
}

.recentlyLaunchedRipple {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.caption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.captionIsLoading {
  width: 100%;
  border-radius: 4px;
  margin-top: -1px;
  height: 12px;
  display: block;
}

.captionContainer {
  width: 100%;
  max-width: 72px;
  padding: 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.shine {
  @include shine(true);
}
