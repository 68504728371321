@import "../../../styles/mixins";

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding-left: 0;
  padding-right: 0;

  .label {
    white-space: nowrap;
  }
}

.heading {
  padding-top: 16px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font_family_rounded);
}

.loader {
  margin-top: 16px;
  height: 173px;
  border-radius: 16px;
  width: 100%;
  @include shine(true);

  @include media-breakpoint-m {
    height: 161px;
  }

  @include media-breakpoint-s {
    height: 153px;
  }
}

.root {
  padding: 12px 16px 24px;
  box-sizing: border-box;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-direction: row;
  margin: 0 -16px;
  justify-content: flex-start;
  scroll-padding-left: 16px;
  scroll-padding-right: 16px;

  @include noScrollbar();

  .caption {
    color: var(--hint_color);
    white-space: nowrap;
    font-family: var(--font_family_rounded);
  }

  .block {
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    gap: 6px;
  }

  .divider {
    width: 16px;
    min-width: 16px;
    margin: auto 0;
    display: block;
    height: 40px;
    position: relative;

    &:before {
        display: inline-block;
        content: '';
        position: absolute;
        width: 1px;
        left: 50%;
        top: 0;
        height: 40px;
        background-color: var(--stroke_separator);
    }
  }

  .positive {
    color: var(--color_green);
  }

  .negative {
    color: var(--color_red);
  }
}

.shine {
  @include shine(true);
}

.separatorWrapper {
  box-sizing: border-box;
  width: 100%;
}

.separator {
  height: 0.5px;
  display: block;
  width: 100%;
  background-color: var(--stroke_separator);
}
