@import '../../../styles/mixins';

.root {
  height: 100vh;
  overflow: hidden;
  background-color: #251517;
  color: white;
}

.inner {
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 32px;
  @include noScrollbar();
  overflow-y: auto;
  overflow-x: hidden;
}

.image {
  position: relative;

  &Bg {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 20px), calc(-50% - 20px));
  }

  &Img {
    position: relative;
    display: block;
    margin: 0 auto;
  }
}

.title {
  &Container {
    padding: 16px 24px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
  }

  color: rgba(255, 255, 255, .66);
}

.subtitle {
  opacity: 0.5;
}

.tasks {
  padding: 20px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.divider {
  margin: 16px;
  background: rgba(229, 229, 234, 0.20);
  height: 0.33px;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  padding: 14px 12px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.10);
  transition: transform 200ms ease;

  &:active {
    transform: scale(.96);
  }
}