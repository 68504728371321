.root {
  padding: 0 4px;
  background-color: var(--link_color);
  border-radius: 4px;
  box-sizing: border-box;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: var(--bg_color);
}
