@import "../../../styles/mixins";

.root {
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 0.5px solid var(--stroke_separator);

  .heading {
    padding-bottom: 8px;
  }
}

.shine {
  @include shine(true);
  width: 100%;
  height: 200px;
  border-radius: 24px;

  * {
    color: transparent !important;
  }
}
