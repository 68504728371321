$leagues-gap: 12px;

.title {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.60) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 40px;
}

.leagues {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-flow: wrap;
  gap: $leagues-gap;
  margin-top: 28px;
}
