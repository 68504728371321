.root {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-radius: 12px;
  margin-bottom: 8px;
}

.texts {
  padding-right: 16px;
  overflow: hidden;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subtitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #8E8E93;
  display: block;
  overflow: hidden;
}

.price {
  flex: 0 0 auto;
}

.shimmer {
  border-radius: 12px;
  margin-bottom: 8px;
}