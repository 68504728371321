.footer {
  padding: 12px 16px;
}

.container {
  padding: 16px 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.panel {
  border: 1.55px solid #FED25D;
}

.subtitle {
  margin-top: 9px;
  color: rgba(255, 255, 255, .5);
  text-align: center;
  padding: 0 16px;
}