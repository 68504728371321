@import "../media";

@mixin schemeLight {
  --bg_color: #FFFFFF;
  --text_color: #222222;
  --hint_color: #8A8F93;
  --link_color: #0287d9;
  --link_alpha_color: #0287d91A;
  --button_text_color: #FFFFFF;
  --button_color: #3390EC;
  --secondary_bg_color: #F1F6F9;
  // 6.10
  --section_bg_color: #FFFFFF; // fallback: bg_color
  --accent_text_color: #0287d9; // fallback: link_color
  --subtitle_text_color: #8A8F93; // fallback: hint_color
  --destructive_text_color: #CC2929;
  --header_bg_color: #527DA3;
  --section_header_text_color: #8A8F93; // fallback: hint_color

  --stroke_separator: #0000001A;
  --stroke_image_border: #00000014;
  --button_color_hover: #5EAEED;
  --secondary_bg_color_hover: #E5Eff5;
  --hint_color_hover: #909090;

  --color_orange: #F68136;
  --color_alpha_orange: #F681361A;
  --color_green: #34C759;
  --color_red: #FF3B30;
  --overlay: #00000066;
  --bg_modal: #FFFFFF;
  --bg_header_blur: #FFFFFFE0;
  --bg_hover_alpha: #1313140A;

  --bg_skeleton: #F1F0F8;
  --bg_skeleton_gradient: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 52.08%, rgba(255, 255, 255, 0) 100%);
  --bg_ripple: #000000A3;

  --card_color: rgba(255, 255, 255, 1);

  --highlight-default: rgb(142 142 146 / 24%);
}
