.root {
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.10);
  width: 100%;

  &:active {
    background: rgba(255, 255, 255, 0.10);
  }
}

.rounded {
  border-radius: 1000px;
}