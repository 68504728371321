.root {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  height: 44px;
  margin-bottom: 8px;
}

.button {
  height: 100%;
  border-radius: 0;
  background: transparent;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  padding: 12px;

  &Disabled {
    color: #8E8E93;

    &:active {
      color: #8E8E93;
    }
  }

  &Left {
    justify-content: flex-start;
  }

  &Right {
    justify-content: flex-end;
  }
}

.count {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
}

.shimmer {
  border-radius: 12px;
}