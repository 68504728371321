@import "../../../styles/mixins";

.root {
  padding: 16px 0 0;
  transform: translateZ(0);

  &.compressedSeparatorOffset {
    padding-top: 8px;
  }

  .header {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    display: inline-block;
    width: fit-content;
  }

  .body {
    overflow-y: scroll;
    scroll-snap-type: x mandatory;
    display: flex;
    flex-direction: row;

    @include noScrollbar();
  }

  .compact {
    overflow-y: initial;
  }

  .button {
    padding-left: 0;
    padding-right: 0;
  }

  .label {
    white-space: nowrap;
  }

  .scrollItem {
    min-width: 1px;
    height: 100%;
    scroll-snap-align: start;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 360px);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
  }

  .separatorWrapper {
    padding: 0 16px 16px;
    box-sizing: border-box;
    width: 100%;
  }

  .separator {
    height: 0.5px;
    display: block;
    width: 100%;
    background-color: var(--stroke_separator);
  }
}
