.progressbar {
  display: flex;
  align-items: flex-end;
  gap: 2px;
}

.progress {
  border-radius: 60px;
  height: 14px;
  background: rgba(255, 255, 255, 0.10);
  flex: 1 0 0;
  transition: all 300ms ease;

  &Dark {
    background: rgba(0, 0, 0, 0.10);
  }

  &Current {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.50) 0%,
        rgba(255, 255, 255, 0.32) 100%
    );

    &Dark {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.32) 100%);
    }
  }
}

.star {
  flex: 0 0 auto;
  color: white;

  &Dark {
    color: black;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  opacity: .6;
  margin-top: 8px;
}