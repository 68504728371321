.root {
  background: #FFFFFF26;
}

.yellow {
  background: rgba(237, 198, 41, 0.15);
}

.red {
  background: rgba(237, 54, 41, 0.15);
}