@import "../../../styles/mixins";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  padding: 12px 16px;
  background: var(--color_alpha_orange);

  .link {
    color: var(--color_orange);
    font-weight: 510;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--color_orange);
    }
  }
}

.shine {
  @include shine(true);

  * {
    color: transparent !important;
  }
}
