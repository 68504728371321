@import "../../styles/mixins";

.root {
  position: sticky;
  display: flex;
  width: 100%;
  flex-direction: row;
  z-index: var(--search_z_index);
  overflow-x: hidden;
  align-items: center;

  &:hover {
    & label input:placeholder-shown + .hintHolder{
      visibility: visible;
      opacity: 1;

      @include media-breakpoint-s {
        opacity: 0;
      }
    }
  }

  @include media-breakpoint-m() {
    gap: 10px
  }
}

.input {
  background-color: var(--secondary_bg_color);
}

.hintHolder {
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  gap: 4px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  transition: opacity .1s var(--third_layer);

  @include media-breakpoint-m {
    transition: none;
  }
}

.actionHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);

  &.android{
    right: 8px;
  }
}

.cancelButton {
  opacity: 0;
  margin-right: -100px;
  padding: 0;

  @include firstLayerTransition(margin-right);

  @include media-breakpoint-s() {
    &.visible {
      opacity: 1;
      margin: 0;
    }
  }

  .label {
   white-space: nowrap;
  }
}

.escHint {
  @include media-breakpoint-m() {
    display: none;
  }
}

.lottieContainer {
  width: 24px;
  height: 24px;
}

.searchResults {
  top: var(--header_height);
}
