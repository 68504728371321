.root {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.10);
}

.rootClickable {
  padding: 0;
}

.ripple {
  padding: 8px 12px;
  height: 100%;
  display: inherit;
  align-items: inherit;
  gap: inherit;
  border-radius: inherit;
}

.subtitle {
  color: rgba(255, 255, 255, 0.60);
}