.panel {
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 1.55px;
    border-radius: inherit;
    background: conic-gradient(from 180deg at 50% 50%, #6A81C0 0deg, #C069DA 61.56deg, #6A81C0 360deg);
    mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
    animation: background-pulse-primary 3s infinite ease-in-out;
    background-size: 200% 200%;
  }

  &:after {
    $gradient-width: 8%;
    $gradient-color: rgba(255, 255, 255, 80%);
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 1.55px;
    border-radius: inherit;
    background: linear-gradient(
        -45deg,
        transparent,
        transparent 50% - $gradient-width,
        $gradient-color 50%,
        $gradient-color 50%,
        transparent 50% + $gradient-width
    ) no-repeat;
    mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
    animation: background-pulse-secondary 5s infinite ease-in-out;
    background-size: 200%;
  }
}

@keyframes background-pulse-primary {
  from {
    background-position: 0 0;
  }
  25% {
    background-position: 100% 0;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0 100%;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes background-pulse-secondary {
  from {
    background-position: -650px 0;
  }
  to {
    background-position: 300px 0;
  }
}

.textContainer {
  border-radius: 1000px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  gap: 3px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 31.89%);
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.50);
}

.points {
  background: linear-gradient(90deg, #6A81C0 0%, #C069DA 20.85%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button {
  border-radius: 10px;
  width: 100%;
  margin-top: 12px;
  height: 50px;
  background: rgba(255, 255, 255, 0.10);
  font-weight: 590;
  font-size: 17px;
  line-height: 22px;
}
