@import "../../../styles/mixins";

.root {
  padding: 16px  0;
}

.section {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @include media-breakpoint-m {
    max-width: 730px;
  }
}

.padllessSectionContainer {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  @include media-breakpoint-m {
    max-width: 730px;
  }
}
