.root {
  padding: 0 16px;
}

.link {
  padding: 10px;
  position: absolute;
  top: 14px;
  right: 6px;
  border-radius: 1000px;

  &Icon {
    display: block;
    opacity: 0.8;
    color: white;
  }
}

.panel {
  border-radius: 24px;
  background: linear-gradient(103deg, #8C68AC 0%, #794EA6 35.79%, #7345A4 100%);
  color: white;
  position: relative;
  overflow: hidden;

  &Inner {
    padding: 16px;
  }

  &Clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}

.title {
  padding-right: 30px;
}

.footer {
  margin-top: 28px;

  &Shimmer {
    height: 42px;
    border-radius: 1000px;
    margin-top: 28px;
  }
}

.headerShimmer {
  &Wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
  }

  &TitleWrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    gap: 12px;
    height: 80px;
    flex: 1 0 0;
  }

  &Arrow {
    flex: 0 0 24px;
    height: 24px;
    border-radius: 50%;
    margin-top: 10px;
  }
}
