$imageSectionHeight: 260px;
$imageSectionHeightSmall: 220px;

.root {
  padding-top: $imageSectionHeight;
  position: relative;
  height: 100%;

  @media screen and (max-width: 400px) {
    padding-top: $imageSectionHeightSmall;
  }
}

.inner {
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $imageSectionHeight;

  @media screen and (max-width: 400px) {
     height: $imageSectionHeightSmall;
  }

  &Bg {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &Img {
    position: absolute;
    display: block;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.title {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.60) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 10px;
  padding: 0 16px;
}

.content {
  height: 100%;
}