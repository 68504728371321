@import '../../../styles/mixins';

.root {
  height: 100vh;
  background-color: #251517;
  color: white;
  @include noScrollbar();
  overflow-y: auto;
  overflow-x: hidden;
}

.stateWrapper {
  height: 100%;
}