.root {
  padding-bottom: 16px;
}

.hidden {
  padding: 8px 10px;
  background: #FFFFFF1A;
  border-radius: 1000px;
  display: inline-block;
  vertical-align: text-bottom;

  &Content {
    display: flex;
    gap: 5px;
    opacity: 0.6;
  }
}