.root {
  padding: 16px 16px 0;
  margin-bottom: 4px;
}

.shimmer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.image {
  max-width: 24px;
  max-height: 24px;
  vertical-align: text-bottom;
}