.root {
  border-radius: 40px 40px 0 0;
  background: #232325;
  padding: 16px 16px 70px;

  &Standalone {
    margin-bottom: -65px;
    background: linear-gradient(180deg, #3D3D3D 0%, #222224 77.14%, #1C1C1E 100%);
  }
}

.title {
  padding: 0 12px;
  color: #8E8E93;
  display: block;
  margin-bottom: 10px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.10);
}