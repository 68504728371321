.root {
  padding-bottom: 12px;
}

.footer {
  height: 42px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 12px;
}

.appShimmer {
  margin: 12px 0 20px;
  display: flex;
  align-items: center;
  gap: 12px;

  &Icon {
    height: 64px;
    width: 64px;
    border-radius: 16px;
  }

  &Info {
    display: flex;
    flex-flow: column;
    gap: 4px;
    flex: 1 0 0;
  }
}

.verifying {
  background: #FFFFFF1A;
  border-radius: 1000px;
  height: 100%;
  width: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 2px;

  &Icon {
    flex: 0 0 18px;
  }
}