@import "../../styles/mixins";

$height: 74px;

.root {
  display: flex;
  position: fixed;
  overflow-y: scroll;
  flex-direction: row;
  justify-content: flex-start;
  scroll-snap-type: x mandatory;
  left: 0;
  width: 100%;
  margin: 0;
  gap: 8px;
  padding: 12px;
  scroll-padding-left: 12px;
  scroll-padding-right: 12px;
  top: var(--header_height);
  z-index: var(--tabs_z_index);
  background-color: var(--bg_color);

  @include noScrollbar();
  @include firstLayerTransition(top);
}

.item {
  min-width: fit-content;
  scroll-snap-align: start;
}

.placeholder {
  height: $height;
  flex: 0 0 $height;
}

.redPacket {
  // temporary solution due to strange padding in tabs.
  margin: -10px 16px 16px;

  &WithRecent {
    margin-bottom: 26px;
  }
}

.title {
  white-space: nowrap;
  transition: color .3s var(--first_layer);
}
