.root {
  padding: 16px 0;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.10);
}

.header {
  margin-bottom: 4px;
  position: relative;
  padding-right: 27px;
}

.action {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  right: -6px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;

  &Icon {
    display: block;
    opacity: .6;
  }
}