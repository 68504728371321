.root {
  border-radius: 40px 40px 0 0;
  background: #232325;
  padding: 16px;
  margin-top: -65px;
  background: linear-gradient(180deg, #3D3D3D 0%, #222224 77.14%, #1C1C1E 100%);
}

.title {
  padding: 0 12px;
  color: #8E8E93;
  display: block;
  margin-bottom: 10px;
}

.walletIcon {
  opacity: 0.9;
  color: white;
  display: block;
}

.items {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.10);
}