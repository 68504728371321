.root {
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  &Highlight {
    z-index: 0;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      inset: 0;
      padding: 1.55px;
      border-radius: inherit;
      background: conic-gradient(from 180deg at 50% 50%, #6A81C0 0deg, #C069DA 61.56deg, #6A81C0 360deg);
      mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
      animation: background-pulse-primary 3s infinite ease-in-out;
      background-size: 200% 200%;
    }

    &:after {
      $gradient-width: 8%;
      $gradient-color: rgba(255, 255, 255, 80%);
      content: "";
      position: absolute;
      z-index: -1;
      inset: 0;
      padding: 1.55px;
      border-radius: inherit;
      background: linear-gradient(
          -45deg,
          transparent,
          transparent 50% - $gradient-width,
          $gradient-color 50%,
          $gradient-color 50%,
          transparent 50% + $gradient-width
      ) no-repeat;
      mask: linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box;
      animation: background-pulse-secondary 5s infinite ease-in-out;
      background-size: 200%;
    }
  }
}

.inner {
  flex: 1 0 auto;
  padding: 16px;
  border-radius: inherit;
}

.title {
  margin-bottom: 4px;
  padding-right: 16px;
}

.text {
  opacity: .6;
  display: block;

  &Compressed {
    width: 60%;
  }
}

.arrow {
  position: absolute;
  top: 22px;
  right: 12px;
}

.background {
  position: absolute;
}
