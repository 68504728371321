@import "../../styles/mixins";

.root {
  padding: 4px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include media-breakpoint-s() {
    grid-template-columns: 1fr;
  }

  &.twa {
    @include noScrollbar();
  }
}

.empty {
  grid-template-columns: 1fr;
}

.scroller {
  height: 100px;
  width: 200px;
}

.spinnerContainer {
  margin: 0 auto;
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 34px;
  height: 34px;
}
