.root {
  position: relative;

  &NoButton {
    padding-bottom: 16px;
  }

  &Highlight {
    border: 1.55px solid #FED25D;
  }
}

.content {
  padding: 0 16px;
  opacity: 0.6;
}

.footer {
  padding: 12px 16px;
}

.buttonText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerWithIcon {
  padding-right: 46px;
}

.completedIcon {
  color: #30D158;
}

.info {
  position: absolute;
  right: 10px;
  top: 12px;
  padding: 6px;
  border-radius: 1000px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.headerIcon {
  display: block;
  opacity: 0.6;
  width: 24px;
  height: 24px;
}