@import "../../../styles/mixins";

.root {
  width: 100%;
  position: relative;
  padding-top: 20px;

  max-width: 980px;
  margin: 0 auto;

  @include media-breakpoint-m {
    max-width: 100%;
    margin: 0;
  }
}

.slider {
  height: 496px;
}

.shadowBlock {
  width: 16px !important;
  margin: 0 !important;
}

.image {
  width: 229px !important;
  height: 496px;

  border-radius: 24px;
}

.expandedWidth {
  width: 235px !important;
  padding-right: 6px;
}

.slide {
  width: 229px !important;
  height: 496px;
}

.shine {
  @include shine(true);
}

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  z-index: 100;

  @include media-breakpoint-m {
    display: none;
  }
}

.buttonLeft {
  left: 0;
}

.icon {
  margin-right: -2px;
  margin-bottom: -2px;
}

.iconLeft {
  transform: rotate(-180deg);
  margin-right: 0;
  margin-left: -2px;
}

.buttonRight {
  right: 0;
}
