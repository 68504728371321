.root {
  &Completed {
    padding-bottom: 6px;
  }
}

.title {
  word-break: break-word;
}

.timer {
  margin-top: 12px;

  &Shimmer {
    border-radius: 100px;
    height: 42px;
  }
}

.appShimmer {
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  &Icon {
    height: 64px;
    width: 64px;
    border-radius: 16px;
  }

  &InfoWrap {
    display: flex;
    flex-flow: column;
    gap: 4px;
    flex: 1 0 0;
  }
}

.description {
  color: white;
  opacity: 0.6;
  word-break: break-word;

  &Markdown {
    & > * {
      margin: 12px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &Shimmer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.rewards {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin: 12px 0 10px;

  &Shimmer {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    margin: 12px 0 10px;
  }
}

.reward {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  border-radius: 100px;
  color: white;
  background: #FFFFFF1A;
}

.buttonWrap {
  margin-top: 20px;
}

.checkIcon {
  vertical-align: bottom;
  margin-left: 4px;
}