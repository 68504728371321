@import "../../../styles/mixins";

.root {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  width: 100%;

  .image {
    width: 114px;
    height: 114px;
    min-width: 114px;
    border-radius: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .subheadline {
      color: var(--hint_color);
      @include text-ellipsis-multiline(2);
    }

    .controls {
      margin-top: auto;
      padding-top: 12px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

.sharingButton {
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 6px;
}

.shine {
  border-radius: 24px;
  @include shine(true);
  color: transparent !important;

  &__shineText {
    max-height: 20px;
  }
}

.runningShine {
  background-image: linear-gradient(270deg, var(--button_color) 48.44%, var(--button_text_color) 75.52%, var(--button_color) 100%);
  background-repeat: no-repeat;
  animation: bg-move linear 5s infinite;
  background-blend-mode: soft-light;
}

@-webkit-keyframes bg-move {
  0%   { background-position: -500px 0; }
  100% { background-position: 1000px 0; }
}

@keyframes bg-move {
  0%   { background-position: -500px 0; }
  100% { background-position: 1000px 0; }
}
