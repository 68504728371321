.root {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .collider {
    &__card {
      width: 46px;
      height: 46px;
      overflow: visible;
      position: absolute;
    }

    &__button {
      border: none;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      vertical-align: center;
      align-items: center;
      gap: 6px;

      &:active {
        background-color: transparent;
      }
    }

    &__spinner {
      display: inline-block;
      width: 16px;
      height: 16px;

      &__root {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__container {
      position: relative;
      cursor: pointer;
      width: 48px;
      height: 48px;
      -webkit-tap-highlight-color: transparent;
    }

    &__item {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &__1 {
        z-index: 2;
        display: block;
        box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.2);
        transform: translate(-50%, -50%) rotate(13deg);
      }

      &__2 {
        z-index: 1;
        display: block;
        transform: translate(-50%, -50%) rotate(2deg);
      }
    }
  }
}

.image {
  border: 0.52px solid var(--light_border);
  border-radius: 12px;
  width: 48px;
  height: 48px;
}
