.root {
  border-radius: 24px;
  border: 1.55px solid #FED25D;
  position: relative;
  overflow: hidden;
  color: white;
  background: rgba(255, 255, 255, 0.10);
}

.shimmer {
  background: rgba(255, 255, 255, 0.10);
  padding: 12px 12px 16px;
  border-radius: 24px;
}

.inner {
  height: 100%;
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding: 16px 16px 0;

  &WithAction {
    padding-right: 43px;
  }

  &Icon {
    vertical-align: bottom;
  }
}

.text {
  opacity: .6;
}

.action {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding: 6px;
  border-radius: 50%;

  &Icon {
    display: block;
  }
}

.body {
  overflow: hidden;
  padding: 4px 16px 16px;

  &HasUnfinihsed {
    padding-bottom: 12px;
  }
}

.button {
  margin-top: 12px;
}
