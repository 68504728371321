.root {
  position: relative;
}

.img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  animation: flying-img 5s infinite ease-in-out;
  transform: translateY(0);
  pointer-events: none;

  &Shimmer {
    border-radius: 40px;
    margin: 20px auto 0;
  }
}

@keyframes flying-img {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
  to {
    transform: translateY(0);
  }
}

.background {
  position: absolute;
  filter: blur(100px);
  width: 100%;
  height: 100%;
  background: no-repeat center -80px;
  background-size: contain;
  left: 0;
  right: 0;
}

.content {
  position: relative;
}
