.root {
  margin-top: 20px;
  border-radius: 12px;

  p + p {
    margin-top: 12px;
  }
}

.shimmer {
  margin-top: 20px;
  border-radius: 12px;
}