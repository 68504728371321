@import '../../../styles/mixins';

.root {
  height: 100vh;
}

.inner {
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
  padding-bottom: 24px;
  @include noScrollbar();
  overflow-y: auto;
}

.image {
  &Section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  &Block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    width: 88px;
    color: var(--button_color);
    background-color: var(--secondary_bg_color);
    border-radius: 24px;
    overflow: hidden;
  }

  width: 52px;
  height: 52px;
  display: block;
}

.title {
  padding: 16px 16px;
  text-align: center;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &Item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 16px;

    &Num {
      height: 40px;
      flex: 0 0 40px;
      max-width: 40px;
    }

    &Text {
      padding: 11px 0;
    }
  }
}