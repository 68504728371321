@import "../../styles/mixins";

.root {
  padding: 36px;

  flex-wrap: wrap;

  color: var(--hint_color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-s {
    padding: 24px 16px;
  }
}

.itemWithIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: inherit;
}

.heroButton {
  color: var(--link_color);
}

.linksButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-family: var(--font-family);
  flex-wrap: wrap;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
  }
}

.copyright {
  padding-top: 16px;
  width: 100%;
  text-align: center;
}
