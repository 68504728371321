@use "sass:map";
@import "./media.scss";

$def-trans-duration: .3s;
$transition-duration: .3s;
$transition-timing-function: ease-in-out;
$transition-duration-fast: .1s;

@mixin with-transition($prop, $prop2: false, $prop3: false) {
  @if $prop3 {
    transition: $prop $transition-duration $transition-timing-function, $prop2 $transition-duration $transition-timing-function, $prop3 $transition-duration $transition-timing-function;
  } @else if $prop2 {
    transition: $prop $transition-duration $transition-timing-function, $prop2 $transition-duration $transition-timing-function;
  } @else {
    transition: $prop $transition-duration $transition-timing-function;
  }
}

@mixin placeholder-color() {
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}

@mixin text-ellipsis($max-width: 100%) {
  overflow: hidden;
  max-width: $max-width;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-ellipsis-multiline($lines-count: 3) {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines-count;
  -webkit-box-orient: vertical;
}

@mixin hover-state($withCustomTransition: false, $cursor: true) {
  @if not $withCustomTransition {
    @include with-transition(background-color, opacity);
  }

  cursor: pointer;

  @if not $cursor {
    cursor: default;
  }

  @content;
}

@mixin noScrollbar() {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin animate($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;
}


@mixin animateAndFreezeLast($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;
  animation-fill-mode: forwards;
}

@mixin keyframes($name){
  @keyframes #{$name}{
    @content;
  }
}

@mixin firstLayerTransition ($prop, $duration: 0.2s, $acceleration: true) {
  transition: $prop $duration var(--first_layer);

  @if $acceleration {
    transform: translateZ(0);
  }
}

@mixin coreLayerTransition ($prop, $duration: 0.3s, $acceleration: true) {
  transition: $prop $duration var(--core_layer);

  @if $acceleration {
    transform: translateZ(0);
  }
}



@mixin shine($once: false) {
  color: transparent;
  background: var(--bg_skeleton);
  display: inline-block;
  position: relative;
  opacity: 0;
  pointer-events: none;
  cursor: default;

  @if $once {
    animation: placeholderShimmer 0.8s linear;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @include keyframes(placeholderShimmer){
      0% {
        opacity: 0.6;
      }

      100% {
        opacity: 1;
      }
    }

    @include animateAndFreezeLast(placeholderShimmer, 1s, linear, 1);
  } @else {
    background-repeat: no-repeat;
    background-size: 800px 104px;
    background-image: var(--bg_skeleton_gradient);

    @include keyframes(placeholderShimmer){
      0% {
        background-position: -468px 0;
      }

      100% {
        background-position: 468px 0;
      }
    }

    @include animate(placeholderShimmer, 0.8s, linear, infinite);
  }
}
