@import "../../../../styles/media";
@import "../../../../styles/mixins";

.root {
  background-color: var(--bg_color);
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: top center;
  width: 100%;
  z-index: var(--search_results_z_index);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  border-radius: 0;
  position: fixed;
  transform: none;
  border: none;
  max-height: none;

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: none;
  }

  @include with-transition(opacity, transform, 0.1s var(--first_layer));

  &.hintVisible {
    padding-bottom: 36px;

    @include media-breakpoint-m {
      padding-bottom: 0;
    }
  }
}

.scrollBlock {
  overflow-y: auto;
  padding: 8px;

  @include noScrollbar();

  @include media-breakpoint-s {
    padding: 0;
  }
}

.hintHolder {
  @include media-breakpoint-m() {
    display: none;
  }

  width: 100%;
  box-sizing: border-box;
  height: 36px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg_modal);
  border-top: var(--border_width) solid var(--stroke_separator);

  display: flex;
  padding: 8px 16px 8px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  gap: 12px;
}

.hintGroup {
  display: flex;
  align-items: center;
  gap: 4px;
}

.hintRow {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.hintCaption {
  color: var(--hint_color);
}
