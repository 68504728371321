.root {
  margin-top: 16px;
}

.title {
  text-align: center;

  &Shimmer {
    margin: 0 auto;
  }
}

.league {
  padding: 4px 8px;
  border-radius: 100px;
  margin: 8px auto 0;
  background: rgba(255, 255, 255, 0.10);
  width: fit-content;
  display: block;

  &Shimmer {
    margin: 8px auto 0;
    border-radius: 100px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  opacity: .6;
  margin-top: 8px;
}
