@import "../../../../styles/mixins";

.refContainer {
    display: flex;
    gap: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    @include noScrollbar();
}
.refAvatarContainer {
    align-self: center;
}
.refAvatar {
    @include shine(true);
    background: #F1F0F8;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
}
.refAvatarText {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}
.refContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-bottom: 0.33px solid #E5E5EA33;
    padding: 10px 0;
    gap: 2px;
    width: 100%;
    overflow: hidden;
}
.refName {
    font-size: 17px;
    line-height: 20px;
    height: 20px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.refDate {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: -0.15px;
    color: #8E8E93;
}
