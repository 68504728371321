@import '../../../styles/mixins';

.root {
    height: 100vh;
    background: #171628;
    color: white;
}

.inner {
    max-width: 720px;
    margin: 0 auto;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 32px;
    @include noScrollbar();
    overflow-y: auto;
}
