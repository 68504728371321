.toast {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%);
    color: #fff;
    background-color: #2d2d2d;
    padding: 9px 16px;
    border-radius: 14px;
    display: flex;
    gap: 16px;
    align-items: center;
    width: calc(100% - 32px);
    font-size: 14px;
}
