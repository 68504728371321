.clearIcon {
  color: var(--hint_color);
  cursor: pointer;

  &:hover {
    color: var(--hint_color_hover);
  }
}

.spinnerWrapper {
  width: 38px;
  height: 38px;
  display: flex;

  .androidSpinner {
    margin: auto;
    width: 24px;
    height: 24px;
  }
}

.androidRippleIcon {
  overflow: hidden;
  display: flex;
  width: 38px;
  position: relative;
  height: 38px;
  border-radius: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
