.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  appearance: none;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 200ms ease;
  position: relative;
}

.loading {
  padding-right: 48px;
  cursor: default;
}

.content {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconLeft {
  display: block;
}

.iconLeft {
  max-height: 24px;
}

.tertiary {
}

.filled {
  color: var(--tg-theme-button-text-color);
  background: var(--tg-theme-button-color);
}

.fullWidth {
  width: 100%;
}

.active {
  &:active {
    transform: scale(0.96);
  }
}

.disabled {
  color: rgba(255, 255, 255, .4);
  cursor: default;
}

.sm {
  padding: 5px 12px;
  min-height: 34px;
}

.md {
  height: 50px;
  border-radius: 10px;
  padding: 0 24px;
}

$shining-width: 270px;
.shining {
  &Root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: inherit;
    border: 1px solid rgba(255, 255, 255, 0.02);
    background-blend-mode: soft-light;
    pointer-events: none;
  }

  position: absolute;
  left: -$shining-width;
  top: -100%;
  bottom: -100%;
  transform: rotate(15deg);
  width: $shining-width;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 35%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 65%,
      rgba(255, 255, 255, 0) 100%
  );
  background-blend-mode: soft-light;
  animation: 4000ms infinite ease shining;
}

.spinner {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  animation: 1s linear infinite rotate;
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0);
  }
  from {
    transform: translateY(-50%) rotate(360deg);
  }
}

@keyframes shining {
  from {
    left: -$shining-width;
  }
  20% {
    left: 100%
  }
  to {
    left: 100%
  }
}