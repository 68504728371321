@import '../../styles/mixins';

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 18px;
  overflow-y: auto;
  max-width: 720px;
  margin: 0 auto;

  .icon {
    color: var(--hint_color);
  }

  @include noScrollbar();
}

.footerWrapper {
  margin-top: auto;
}

