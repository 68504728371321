.root {
  border-radius: 24px;
  border: 1.55px solid #FED25D;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;

  &Streaks {
    color: white;
    background: rgba(255, 255, 255, 0.10);
  }
}

.ripple {
  height: 100%;
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding: 16px 50px 0 16px;

  &Icon {
    vertical-align: bottom;
  }
}

.content {
  padding: 0 16px;
  margin-top: 4px;
  opacity: .6;
}

.footer {
  padding: 14px 16px 12px;
}

.action {
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  color: var(--hint_color);

  &Streaks {
    color: rgba(255, 255, 255, 0.8);
  }
}