@import "../../../styles/mixins";

.root {
  --border-radius: 28px;

  padding: 8px 16px;

  .ellipsis_1 {
    @include text-ellipsis-multiline(1);
  }

  .ellipsis_2 {
    @include text-ellipsis-multiline(2);
  }

  .ellipsis_3 {
    @include text-ellipsis-multiline(3);
  }

  .text {
    color: var(--color_white);
    word-break: break-word;
  }

  .container {
    background: var(--background-gradient-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    padding-top: 24px;
  }

  .containerEmpty {
    padding-top: 0;
  }

  .header {
    padding: 0 16px 24px;
    margin-top: -4px;
    margin-bottom: -6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    .tag {
      background: var(--bg-alpha);
      padding: 2.5px 5px;
      border-radius: 4px;
      line-height: 13px;
    }
  }

  .body {
    padding: 0 16px 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .entityFooter {
    padding: 0 16px 16px;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .entityButtonWrapper {
    width: 100%;
    border-radius: 8px;
  }

  .entityButton {
    width: 100%;
    padding: 14px 24px;
    background: var(--bg-alpha);
    outline: none;
    border: none;
  }

  .entityButtonLabel {
    color: var(--color_white);
    display: block;
  }

  .footer {
    padding: 12px 16px 14px 16px;
    background: var(--bg-alpha);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .entity__body {
      flex-grow: 1;
      height: fit-content;
    }

    .entity__image {
      width: 48px;
      height: 48px;
      border-radius: 12px;
    }
  }
}
