.root {
  padding: 0 16px;
}

.items {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 8px 12px;
  margin-top: 12px;
}

.section {
  & + & {
    margin-top: 24px;
  }
}