.root {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.panel {
  // todo: set 120px when pocket is back
  min-height: 80px;
  position: relative;
  flex: 1 0 calc(50% - 6px);
}

.soon {
  display: block;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  background: #E034E3;
  box-shadow: 0 0 16px 0 rgba(251, 58, 255, 0.60);
  transform: rotate(-30deg);
  transform-origin: left bottom;

  &Wrap {
    left: 100%;
    bottom: 0;
    transform: translate(calc(-100% - 5px), -10px);
  }
}

.walletConnected {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparentize(#30D158, 0.9);
  position: relative;
  animation: 2s infinite wallet-root-resize ease-in-out;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    background: #30D158;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    animation: 2s infinite wallet-resize ease-in-out;
  }

  &:before {
    width: 24px;
    height: 24px;
    opacity: .2;
    animation-delay: 200ms;
  }

  &:after {
    width: 16px;
    height: 16px;
    background-color: #30D158;
    animation-delay: 400ms;
  }
}

@keyframes wallet-root-resize {
  from, to {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes wallet-resize {
  from, to {
    transform: scale(1) translate(-50%, -50%);
  }
  50% {
    transform: scale(1.15) translate(-50%, -50%);
  }
}