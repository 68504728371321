.root {
  border-radius: 6px;
  animation: root-animation 2s infinite ease-in-out;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.10);

  &:before {
    content: "";
    display: block;
    height: 100%;
    animation: shimmer-animation 2s infinite ease-in-out;
    $gradient-width: 10%;
    $gradient-color: rgba(255, 255, 255, 20%);
    background: linear-gradient(
        -45deg,
        transparent,
        transparent 50% - $gradient-width,
        $gradient-color 50%,
        $gradient-color 50%,
        transparent 50% + $gradient-width
    ) no-repeat;
    background-size: 300% 100%;
  }
}

.rounded {
  border-radius: 1000px;
}

@keyframes shimmer-animation {
  from {
    opacity: 0;
    background-position: -650px 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    background-position: 300px 0;
  }
}

@keyframes root-animation {
  from {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}
