.title {
  margin-bottom: 4px;
}

.icon {
  vertical-align: bottom;
}

.content {
  opacity: 0.6;
}

.button {
  margin-top: 12px;
}