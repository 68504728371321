.root {
  flex: 0 0 calc(50% - 6px);
  padding: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.inner {
  padding-bottom: 52px;
  height: 100%;
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  &Bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &Wrap {
    pointer-events: none;
    position: relative;
    z-index: 0;
    border-radius: 0 0 12px 12px;
    height: 180px;
    overflow: hidden;
  }
}

.content {
  padding: 10px 12px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  line-height: 18px;
  opacity: 0.6;
}

.control {
  height: 30px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  left: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1000px;
}

.button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  &Disabled {
    color: #8E8E93;

    &:active {
      color: #8E8E93;
    }
  }

  &Lock {
    margin-bottom: -1px;
  }
}

.countControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 12px;
}

.countControl {
  flex: 0 0 auto;
  width: auto;
  background: transparent;
  font: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;

  &Disabled {
    color: #8E8E93;

    &:active {
      color: #8E8E93;
    }
  }
}

.priceText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.countSelected {
  flex: 0 0 auto;
}

