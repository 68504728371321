.root {
  margin-top: 18px;
}

.star {
  border-radius: 50%;
  flex: 0 0 16px;
  height: 100%;
}

.bar {
  display: flex;
  align-items: center;
  gap: 3px;
  height: 16px;

  &Item {
    flex: 1 0 0;
    height: 100%;
  }
}

.meta {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}