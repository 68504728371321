.root {
  pointer-events: none;
  position: relative;
  z-index: 0;
  border-radius: 43px;
  padding-bottom: 264 / 360 * 100%;
  overflow: hidden;
  margin-bottom: 12px;
}

.background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.shimmer {
  padding-bottom: 264 / 360 * 100%;
  margin-bottom: 12px;
  border-radius: 43px;
}