$gap: 12px;

$background-size: 100% 239px;

.root {
  flex: 1 0 calc(50% - $gap / 2);
  padding: 0;
  box-sizing: border-box;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  color: white;
  background-color: rgba(255, 255, 255, 0.10);
  background-size: $background-size;
  background-position: center -10px;
  background-repeat: no-repeat;
  animation: background-pulse 4s infinite ease-in-out;
}

@keyframes background-pulse {
  0% {
    background-size: $background-size;
  }
  50% {
    background-size: 120% 260px;
  }
  100% {
    background-size: $background-size;
  }
}

.header {
  padding: 16px 16px 0;
}

.streak {
  display: block;
  opacity: .6;

  &Shimmer {
    display: block;
    margin: 4px 0 8px;
    height: 16px;
  }
}

.content {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
}

@keyframes flying-img {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}

.img {
  display: block;
  margin-top: 6px;
  object-position: center;
  object-fit: contain;
  height: 150px;
  width: 100%;
  animation: flying-img 5s infinite ease-in-out;
  transform: translateY(0);
  pointer-events: none;

  &Shimmer {
    border-radius: 25px;
    margin: 20px auto 10px;
  }
}

.participants {
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: .6;

  &Shimmer {
    margin-bottom: 4px;
  }
}

.rewards {
  display: inline-flex;
  align-items: center;
  gap: 3px;
  color: #C49864;
  text-transform: uppercase;
  padding: 2px 4px;
  box-sizing: border-box;
  border-radius: 4px;
  background: rgba(196, 152, 101, 0.15);
  margin-top: 7px;
}

.footer {
  padding: 16px;
}