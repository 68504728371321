@import "mixins";
@import "css-variables/scheme-dark";
@import "css-variables/scheme-light";
@import "css-variables/shared";

:root[color-scheme="dark"] {
  @include schemeDark();
}

:root {
  @include schemeLight();
  @include shared();
}

/* Misc. */
@import "reset";
@import "html-body";
@import "misc";
