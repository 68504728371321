@import "../../styles/mixins";

.root {
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  color: var(--text_color);

  * & {
    cursor: pointer;
  }

  .hover-state {
    @include hover-state() {
      &:hover {
        background-color: var(--secondary_bg_color_hover);
        color: var(--link_color);
      }
    }
  }
}

.ripple {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 28, 0.06), 0 0 1px 0 rgba(0, 0, 28, 0.12);
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--card_color);
}
