@import "../../../styles/mixins";

.root {
  height: calc(100vh + 1px);
  box-sizing: border-box;
  overflow-y: auto;
  background: #1C1C1E;
  color: white;
  padding: 16px 16px 32px;
  @include noScrollbar();
}

.inner {
  max-width: 400px;
  margin: 0 auto;
  height: 100%;
}