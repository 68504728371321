@import "../../../styles/media";
@import '../../../styles/mixins';

.referralContainer {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
    height: 100vh;
    background-color: #1C1C1E;
    @include noScrollbar();
}
.card {
    background-color: #FFFFFF1A;
    border-radius: 24px;
}
.cardHeading {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 4px 16px;
    align-items: center;
}
.cardHeadingTitle {
    display: flex;
    gap: 4px;
    align-items: baseline;
    color: #FFF;
}
.cardHeadingHint {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.45px;
    font-weight: 590;
    color: #8E8E93;
}
.cardHeadingLink {
    color: #8E8E93;
    font-size: 17px;
    line-height: 22px;
    font-weight: 400;
}
.cardContainer {
    padding: 8px 16px 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.refLink {
    cursor: pointer;
    background-color: #7474801F;
    border-radius: 12px;
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    gap: 32px;
    color: #fff;
}
.rippleWrapper {
    width: 100%;
    border-radius: 8px;
}
.shareBtn {
    width: 100%;
    background-color: #FFFFFF26;
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    font-weight: 590;
    border-radius: 10px;
    border: none;
    padding: 14px 0;
    cursor: pointer;
}
.inviteesContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
}
.inviteesContainer > div:last-of-type > div:last-of-type  {
    border-bottom: none;
}

.stepsContainer {
    padding: 0 16px 11px 16px;
}
.step {
    padding: 10px 0;
    display: flex;
    gap: 8px;
    align-items: center;
}
.stepIndex {
    background-color: #FFFFFF;
    color: #000000;
    min-width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 18px;
    font-weight: 800;
}
.stepText {
    font-size: 17px;
    line-height: 22px;
    font-weight: 400;
    color: #fff;
}

.stepText > span {
    background: linear-gradient(90deg, #B15DCA 14.63%, #5F92E4 37.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
}
