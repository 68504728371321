@import "../../../styles/mixins";

.root {
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.shine {
  @include shine()
}
