.root {
  .checkbox {
    width: 22px;
    height: 22px;
    border: 1px solid var(--stroke_separator);
    border-radius: 50%;
    display: flex;

    .checkboxIcon {
      color: var(--color_white);
      margin: auto;
      visibility:hidden;
    }
  }

  .label {
    gap: 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    input[type=radio] {
      position:absolute;
      visibility:hidden;
    }

    input[type=radio]:checked + div{
      background-color: var(--button_color);
      border: none;

      .checkboxIcon {
        visibility:visible;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }
}
