@import '../../../styles/mixins';

.root {
  height: 100vh;
  overflow-y: auto;
  @include noScrollbar();
  display: flex;
  flex-direction: column;
}

.image {
  &Container {
    position: relative;
    background: #171628;
    height: 60%;
    max-height: 390px;
    overflow: hidden;
  }

  &Bg {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &Img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.inner {
  max-width: 720px;
  margin: 0 auto;
  padding: 24px 16px 24px;
  text-align: center;
}

.title {
  margin-bottom: 8px;
  font-size: 22px;
  line-height: 28px;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: fit-content;
  margin: 16px auto 0;
  position: relative;

  &Layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: currentColor;
    opacity: 0.1;
    border-radius: 1000px;
  }

  &Item {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: currentColor;
    opacity: 0.3;

    &Active {
      opacity: 1;
    }
  }
}