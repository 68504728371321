@import "../../../styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  background: #1C1C1E;
  color: white;
  padding-top: 16px;
  padding-bottom: 32px;
  @include noScrollbar();
}

.inner {
  max-width: 400px;
  margin: 0 auto;
  height: 100%;
}
