.root {
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;

  .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    vertical-align: center;
    align-items: center;
    gap: 20px;
  }
}
