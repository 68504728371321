.root {
  border-radius: 1000px;
  overflow: hidden;
  background: #FFFFFF1A;
  position: relative;
}

.img {
  margin-right: 5px;
  margin-bottom: -4px;
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #FFFFFF1A;
  transition: width 200ms;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  padding: 12px 16px 12px 12px;
  opacity: 0.9;
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 50px;
  box-sizing: border-box;
  overflow: hidden;
}

.timeLeft {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}